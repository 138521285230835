.App {
  text-align: center;
  min-height: "100vh !important";
  max-height: "fit-content";
  width: "100%";
}





