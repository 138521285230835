

  

  .swiper {
    width: calc(100vw - 310px);
    height: 200px;
    height: 250px;

  }
  @media screen and (max-width: 650px) {
    .swiper {
      width:88vw ;
    height: 250px;
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide {
    width: 325px;
  }
  
  /* .swiper-slide:nth-child(2n) {
    width: 40%;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 20%;
  } */
 
  