body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto")
   url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
  }
  *{
    font-family: "Roboto"
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: #f5f4f4 !important;
  }



@font-face {
  font-family: "Roboto";
  src: local("Roboto")
   url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
  }


  ::-webkit-scrollbar{width:3px;  height:10px}
  ::-webkit-scrollbar-thumb{background-color:#adadad;}

  /* .empcnic {

    animation-name: example;
    animation-duration: 1s;
  }
  
  @keyframes example {
    from {transform: rotateY(0deg);}
    to {transform: rotateY(180deg);}
  } */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  } 
  .PhoneInputInput{
  padding:9.5px;
  border-radius: 5px;
  font-size: 16px;
  color: rgb(48, 48, 48);
  border: 1px solid rgb(187, 187, 187);
  
  }
.MuiDataGrid-columnHeaders{
  background:#53b7c5;
  color: white;
}